<template>
  <div class="min-vh-100 bg-primary d-flex flex-column justify-content-center">
    <div class="py-9 my-auto col-md-6 offset-md-3">
      <h1 class="text-center text-light fs-1">Страница не найдена</h1>
      <div class="py-5 text-center text-light">
        Данная страница отсутствует на нашем сервисе. Если адррес введен правильно, пожалуйста, обратитесь к
        администратору
      </div>
      <div class="py-5 text-center">
        <a href="/" class="btn btn-light mx-4">Главная</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>

<style scoped></style>
