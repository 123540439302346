<template>
  <div>
    <div class="bg-primary d-flex py-5">
      <div class="mx-auto">
        <h2 class="text-light text-uppercase font-weight-bold">
          Нужна помощь?
        </h2>
        <h5
          class="text-start text-light text text-uppercase font-weight-lighter mt-3"
        >
          <div>Суд?</div>
          <div>Подготовка документов?</div>
          <div>Банкротство?</div>
          <div>Налоги?</div>
        </h5>
        <div class="mt-2">
          <h3 class="text-light text-uppercase font-weight-bold text-start text-md-center">
            Звони прямо сейчас!
          </h3>
          <div class="d-none d-md-block text-center">
            <a href="tel:79297750475" class="text-light text-lg font-weight-bold">
              +7 929-775-04-75
            </a>
          </div>
          <div class="text-center mt-2">
            <a href="tel:79297750475" class="text-light text-lg mx-2">
              <i class="fas fa-phone"></i>
            </a>
            <a href="https://wa.me/+79297750475" target="_blank" class="text-light text-lg mx-2">
              <i class="fab fa-viber"></i>
            </a>
            <a href="https://t.me/NataliyaJurist" target="_blank" class="text-light text-lg mx-2">
              <i class="fab fa-telegram"></i>
            </a>
            <a href="mailto:potapovajurist@mail.ru" target="_blank" class="text-light text-lg mx-2">
              <i class="far fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 px-2">
      <div class="d-flex">
        <div class="d-flex mx-auto">
          <div class="flex-column my-auto">
            <img src="@/assets/img/avatar-min.jpeg" class="avatar avatar-xl rounded-circle me-3"/>
          </div>
          <div class="flex-column me-auto my-auto">
            <h1 class="text-uppercase text-lg">НАТАЛИЯ ПОТАПОВА</h1>
            <a href="tel:79297750475" class="text-primary text-lg d-block text-center font-weight-bold">
              +7 929-775-04-75
            </a>
          </div>
          <div class="flex-column ms-auto"></div>
        </div>
      </div>
    </div>

    <hr class="horizontal dark my-5"/>

    <div class="container mt-5">
      <div class="row">
        <div class="col-md-3">
          <div class="text-center">
            <div class="text-primary font-weight-lighter" style="font-size: 3rem">17+</div>
            <div class="text-lg font-weight-bold">Лет стажа</div>
          </div>
        </div>
        <hr class="horizontal dark my-4 d-block d-md-none"/>
        <div class="col-md-3">
          <div class="text-center">
            <div class="text-primary font-weight-lighter" style="font-size: 3rem">500+</div>
            <div class="text-lg font-weight-bold">Выигранных дел</div>
          </div>
        </div>
        <hr class="horizontal dark my-4 d-block d-md-none"/>
        <div class="col-md-3">
          <div class="text-center">
            <div class="text-primary font-weight-lighter" style="font-size: 3rem">5+</div>
            <div class="text-lg font-weight-bold">Постоянных абонентов</div>
          </div>
        </div>
        <hr class="horizontal dark my-4 d-block d-md-none"/>
        <div class="col-md-3">
          <div class="text-center">
            <div class="text-primary font-weight-lighter" style="font-size: 3rem">1000+</div>
            <div class="text-lg font-weight-bold">Консультаций</div>
          </div>
        </div>
      </div>
    </div>

    <hr class="horizontal dark my-5"/>

    <h2 class="text-center text-primary mt-5">Полный спектр услуг</h2>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-3 mt-2">
          <div class="text-center">
            <div class="text-primary font-weight-lighter"><i class="fas fa-5x fa-user-shield text-primary"></i></div>
            <div class="text-md font-weight-bold">Защита прав и интересов граждан в суде</div>
          </div>
        </div>
        <hr class="horizontal dark my-4 d-block d-md-none"/>
        <div class="col-md-3 mt-2">
          <div class="text-center">
            <div class="text-primary font-weight-lighter"><i class="fas fa-5x fa-umbrella-beach"></i></div>
            <div class="text-md font-weight-bold">Сопровождение деятельности юридических лиц</div>
          </div>
        </div>
        <hr class="horizontal dark my-4 d-block d-md-none"/>
        <div class="col-md-3 mt-2">
          <div class="text-center">
            <div class="text-primary font-weight-lighter"><i class="fas fa-5x fa-balance-scale-left"></i></div>
            <div class="text-md font-weight-bold">Представление интересов в судебных спорах</div>
          </div>
        </div>
        <hr class="horizontal dark my-4 d-block d-md-none"/>
        <div class="col-md-3 mt-2">
          <div class="text-center">
            <div class="text-primary font-weight-lighter"><i class="fas fa-5x fa-business-time"></i></div>
            <div class="text-md font-weight-bold">Абонентское обслуживание юридических лиц</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary d-flex text-center mt-5 py-5 px-2">
      <div class="my-auto mx-auto">
        <h2 class="text-light my-0 text-uppercase font-weight-bold">Звоните прямо сейчас</h2>
        <div class="text-light text-lg text-uppercase">и мы решим ваши проблемы</div>

        <div class="d-none d-md-block text-center mt-5">
          <a href="tel:79297750475" class="text-light text-lg font-weight-bold">
            +7 929-775-04-75
          </a>
        </div>
        <div class="text-center mt-2">
          <a href="tel:79297750475" class="text-light text-lg mx-2">
            <i class="fas fa-phone fa-2x"></i>
          </a>
          <a href="https://wa.me/+79297750475" target="_blank" class="text-light text-lg mx-2">
            <i class="fab fa-viber fa-2x"></i>
          </a>
          <a href="https://t.me/NataliyaJurist" target="_blank" class="text-light text-lg mx-2">
            <i class="fab fa-telegram fa-2x"></i>
          </a>
          <a href="mailto:potapovajurist@mail.ru" target="_blank" class="text-light text-lg mx-2">
            <i class="far fa-envelope fa-2x"></i>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import "vue3-canvas-video-player/dist/style.css";

export default {
  name: "IndexPage",
  components: {},
  props: {
    msg: String,
  },
};
</script>

<style scoped></style>
