import IndexPage from "@/pages/IndexPage";
import { createRouter, createWebHistory } from "vue-router";
import NotFoundPage from "@/pages/NotFoundPage";

const routes = [
  {
    path: "/",
    name: "IndexPage",
    components: { default: IndexPage },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    components: { default: NotFoundPage },
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
